:root {
}

$black_color: #181818;
$grey_color: #8B8B8B;
$white_color: #ffffff;
$semi_transparent_white_color: rgba(255, 255, 255, 0.28);
$main_color: #12ecd1;
$light_main_color: #0EC7B3;
$orange_color: #FE6238;
