.start-trading {
  padding-block-end: 64px;
}

.start-trading__title,
.start-trading__subscription {
  text-align: center;
}

.start-trading  .triggers__list {
  grid-template-columns: repeat(3, 1fr);
}

.start-trading .triggers__item:not(:last-child)::after {
  content: '';
  display: block;
  width: 32px;
  height: 32px;
  background-image: url(../../assets/img/ic-arrow.svg);
  inset-block-start: 50%;
  transform: translateY(-50%);
  position: absolute;
  inset-inline-end: 0;
  background-color: transparent;
}

.start-trading .triggers__item-ic {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  color: $main_color;
  margin-block-end: 16px;
  display: inline-block;
}

.start-trading__desc {
  font-size: 16px;
  margin: 0 auto;
  margin-block-start: 8px;
  max-width: 224px;
}

.start-trading__content-actions {
  z-index: 1;
  position: relative;
  text-align: center;
  padding-block-start: 40px;
}

@media (max-width: 991px) {
  .start-trading .triggers__item:not(:last-child)::after {
    content: none;
  }
}

@media (max-width: 767px) {
  .start-trading .triggers__list {
    grid-template-columns: 1fr;
  }
}
