.questions {
   padding-block-end: 80px;
   background: linear-gradient(180.00deg, rgba(1, 1, 1, 0),rgb(1, 1, 1) 11.5%);
   position: relative;
 }

.questions::before {
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  inset-block-start: -120px;
  height: calc(100% + 120px);
  background: linear-gradient(180.00deg, rgba(1, 1, 1, 0),rgb(1, 1, 1) 11.5%);
}

.questions__form {
  display: flex;
  column-gap: 24px;
  justify-content: center;
}

.questions__form fieldset {
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.questions__field {
  box-sizing: border-box;
  border: 2px solid rgb(18, 236, 209);
  border-radius: 15px;
  color: $white_color;
  font-size: 24px;
  min-height: 90px;
  padding: 28px 31px;
  background-color: transparent;
  margin-block-end: 16px;
}

.questions__field::placeholder {
  color: $white_color;
  font-size: 24px;
}

.questions__field--textarea {
  resize: none;
  min-height: 193px;
}

.questions__form-submit-btn {
  min-height: 85px;
}

@media (max-width: 991px) {
  .questions__form {
    flex-wrap: wrap;
  }

  .questions__form fieldset,
  .questions__form fieldset label,
  .questions__form fieldset input,
  .questions__form fieldset textarea {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .questions__field {
    min-height: 50px;
    padding: 18px 25px;
  }

  .questions__field--textarea {
    min-height: 120px;
  }

  .questions__field::placeholder {
    color: $white_color;
    font-size: 16px;
  }

  .questions__form-submit-btn {
    min-height: 50px;
  }
}
