.about-hero {
  background-image: url(../../assets/img/about-hero.png);
}
 
.times {
  padding-block-start: 65px;
  padding-block-end: 64px;
}

.times__title {
  text-align: center;
  margin-block-end: 54px;
}
.about-color {
  background: rgb(22,18,18);
  background: linear-gradient(360deg, rgba(22,18,18,1) 0%, rgba(23,19,19,1) 100%);
}