@import 'modules/_colors';
$transition_base: ease-in-out 0.3s;

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  //outline: 1px solid;
}

body {
  background: linear-gradient(314deg, #202020 0%, #292929 50.13%, #202020 78.52%, #202020 100%);
  color: $black_color;
  margin: 0;
  padding: 0;
  font-family: 'Gilroy', sans-serif;
  /* Don't forget about fallback font/family */
  font-size: 20px;
  line-height: normal;
  min-height: 100vh;
  position: relative;
}

#top {
  opacity: 0;
  visibility: hidden;
  height: 0;
  font-size: 0;
}

nav ul {
  list-style: none;
}

.logo {
  display: inline-block;
  height: auto;
}

.logo > img {
  object-fit: contain;
}

img {
  max-width: 100%;
  height: auto;
}

nav a {
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

a.button:focus,
a.button:focus-visible {
  outline: 1px solid lighten($main_color, 45%);
}

.nav-menu {
  margin: 0;
  padding: 0;
}

.to-top-button {
  position: fixed;
  inset-block-end: 50px;
  inset-inline-end: 50px;
  display: block;
  background-image: url(../../assets/img/ic-top-arrow.svg);
  background-position: center center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  background-color: $light_main_color;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  transition: $transition_base;
}

.to-top-button--visible {
  visibility: visible;
  opacity: 1;
  transition: $transition_base;
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
}

//MEDIA
@media (max-width: 576px) {
  .to-top-button {
    width: 30px;
    height: 30px;
    inset-block-end: 20px;
    inset-inline-end: 10px;
    background-size: 70%;
  }
}

@import 'modules/fonts';
@import 'modules/a11y';
@import 'modules/grid';
@import 'modules/components';
@import 'modules/header';
@import 'modules/hero';
@import 'modules/triggers';
@import 'modules/slider';
@import 'modules/start-trading';
@import 'modules/calendar';
@import 'modules/questions';
@import 'modules/trade';
@import 'modules/get-start';
@import 'modules/inner-pages/about';
@import 'modules/inner-pages/trading';
@import 'modules/inner-pages/study';
@import 'modules/inner-pages/news';
@import 'modules/footer';

//@import 'modules/footer';

@import '../../node_modules/tiny-slider/src/tiny-slider';
