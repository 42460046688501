.button {
  color: $black_color;
  border: none;
  border-radius: 15px;
  min-height: 64px;
  padding: 20px 37px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $main_color;
  cursor: pointer;
  letter-spacing: 0.03em;
  font-family: inherit;
  font-weight: 700;
  font-size: clamp(16px, 3vw, 20px);
  line-height: normal;
  transition: $transition_base;
  text-decoration: none;
  box-shadow: 10px 10px 20px 0 rgba(211, 174, 14, 0.1), -10px -10px 20px 0 rgba(255, 236, 152, 0.1);
  text-transform: uppercase;
}

.button:hover {
  transform: scale(1.01);
  transition: $transition_base;
}

.button:active {
  transform: translate(2px, 1px) scale(0.99);
  transition: none;
}

@media (max-width: 768px) {
  .button {
    min-height: 45px;
    padding: 12px 20px;
  }
}
