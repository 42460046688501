.news-hero {
  background-image: url(../../assets/img/news-hero.png);
}

.news {
  padding: 64px 0;
}

.news__title {
  text-align: center;
}

.news__list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-block-start: 54px;
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.news__item {
  // backdrop-filter: blur(7px);
  // box-shadow: -20px -20px 40px 0 rgba(118, 251, 237, 0.05), 20px 20px 20px 0 rgba(14, 199, 179, 0.05);
  background-color: transparent;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  border: 1px solid $main_color;
}

.news__item-img {
  min-width: 200px;
  max-width: 200px;
}

.news__item-img > img {
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.news__content {
  position: relative;
  padding: 24px;
  padding-block-start: 80px;
  padding-block-end: 36px;
}

.news__data {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  // border: 1px solid $orange_color;
  border-left: none;
  // border-radius: 0 64px 64px 0;
  color: $main_color;
  padding: 20px 24px;
}

.news__tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $main_color;
  color: $black_color;
  padding: 5px 14px;
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  top: 22px;
  right: 24px;
  text-transform: uppercase;
}

.news__desc {
  margin-block-end: 24px;
  font-size: 20px;
  color: $white_color;
}
.news .button {
  line-height: 16px;
  padding: 12px 24px;
  min-height: 48px;
}
.pagination {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-block-start: 32px;
}

.pagination__button {
  border-radius: 50%;
  color: $black_color;
  width: 40px;
  height: 40px;
  min-height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  padding: 0;
  font-weight: 300;
  padding-block-start: 3px;
  background: transparent;
  border: 1px solid $main_color;
  border-radius: 5px;
  color: $main_color;
  box-shadow: none;
}

.pagination__button:hover {
  transform: none;
}

.pagination__button--is-active {
  background-color: $main_color;
  color: $black_color;
}

.news__tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.news__tabs .tab-button {
  min-width: unset;
  text-transform: uppercase;
  font-size: 12px;
  padding: 8px 18px;
  min-height: unset;
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .news__list {
    grid-template-columns: minmax(0, 592px);
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .news__item-img {
    min-width: 120px;
    max-width: 120px;
  }
}

@media (max-width: 480px) {
  .news__tag {
    padding: 10px 38px;
    padding-inline-start: 30px;
  }

  .news__data {
    padding: 10px 18px;
  }
}

.news {
  background: rgb(18,15,15);
background: linear-gradient(360deg, rgba(18,15,15,1) 0%, rgba(32,32,32,1) 100%);
}
.calendar {
  top: -10px;
  position: relative;
}
.calendar.calendar-bg {
padding-top: 0;
padding-bottom: 0;
top: 0;
}

.triggers.start-trading {
  top: -10px;
}
