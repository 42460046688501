.first-title {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-size: clamp(36px, 5vw, 80px);
  //letter-spacing: clamp(.28px, 3vw, .4px);
  line-height: normal;
  font-weight: 700;
  color: $white_color;
  margin: 0;
}

.second-title {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-size: clamp(28px, 3vw, 40px);
  //letter-spacing: clamp(.28px, 3vw, .4px);
  line-height: normal;
  font-weight: 700;
  color: $white_color;
  margin: 0;
}

.third-title {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-size: clamp(26px, 3vw, 32px);
  //letter-spacing: clamp(.24px, 3vw, .28px);
  line-height: normal;
  font-weight: 700;
  color: $white_color;
  margin: 0;
}

.small-title {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-size: 20px;
  //font-size: clamp(18px, 5vw, 20px);
  letter-spacing: .2px;
  font-weight: 700;
  line-height: normal;
  color: $white_color;
  margin: 0;
}

.main-text {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: clamp(16px, 2vw, 24px);
  //letter-spacing: clamp(.16px, 2vw, .2px);
  line-height: normal;
  color: $white_color;
  margin: 0;
}

.small-text {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: .16px;
  line-height: normal;
  color: $white_color;
  margin: 0;
}

.white-text {
  color: $white_color;
}
