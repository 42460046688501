.trading-hero {
  background-image: url(../../assets/img/trading-hero.png);
}

.tariffs {
  padding-block-start: 64px;
  background: rgb(23,19,19);
background: linear-gradient(360deg, rgba(23,19,19,1) 0%, rgba(31,31,31,1) 100%);
padding-bottom: 4em;
}

.tariffs__title {
  text-align: center;
}

.tariffs__plans {
  margin: 0;
  padding: 0;
  list-style: none;
}

.plans {
  padding-block-start: 54px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
  width: 100%;
}

.plans__item {
  // background-color: $semi_transparent_white_color;
  border-radius: 64px;
  // box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05),20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  padding: 48px 39px;
  padding-block-end: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $main_color;
}

.plans__item-title {
  text-align: center;
  color: $main_color;
}

.plans__services {
  margin: 0;
  padding: 0;
  padding-block-start: 24px;
  padding-block-end: 36px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
}

.plans__item-price {
  text-align: center;
  margin-block-end: 16px;
}

.plans__service--grey {
  color: $grey_color;
}

@media (max-width: 991px) {
  .plans {
    gap: 32px;
    flex-wrap: wrap;
  }
}
