.triggers {
  padding-block-start: 80px;
  padding-block-end: 80px;
  background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 14.5%, #171313 100%);
  position: relative;
}

.triggers::before {
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  inset-block-start: -100px;
  height: calc(100% + 100px);
  background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 14.5%, #171313 100%);
}

.triggers__title,
.triggers__subscription {
  text-align: center;
  z-index: 1;
  position: relative;
}

.triggers__subscription {
  margin-block-start: 20px;
  margin-block-end: 40px;
}

.triggers__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
  width: 100%;
  justify-content: center;
  text-align: center;
  border-radius: 64px;
  border: 2px solid #12ecd1;
  padding: 74px 0;
  margin: 0;
  backdrop-filter: blur(7px);
}

.triggers__item-title {
  color: $white_color;
  // @extend .main-text;
  font-weight: 700;
  font-size: 16px;
}

.triggers__item {
  position: relative;
}

.triggers__item:not(:last-child)::after {
  content: '';
  display: block;
  width: 1px;
  height: 140px;
  inset-block-start: 50%;
  transform: translateY(-50%);
  background-color: #0E0D11;
  position: absolute;
  inset-inline-end: 0;
}

@media (max-width: 767px) {
  .triggers__list {
    grid-template-columns: 1fr;
    padding: 44px 0;
    gap: 50px;
  }

  .triggers__item:not(:last-child)::after {
    content: none;
  }

  .triggers__item {
    padding: 0 10px;
  }

  .triggers__item-text {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .triggers__item:not(:last-child)::after {
    height: 1px;
    width: 100px;
    inset-block-start: auto;
    inset-block-end: -25px;
    inset-inline-start: 50%;
    transform: translate(-50%, 0);
    background-color: #0ec7b3;
    position: absolute;
  }
}
