.calendar {
  padding-block-start: 80px;
  padding-block-end: 64px;
  //background-color: rgb(23, 19, 19, 1);
  background: linear-gradient(180.00deg, rgb(23, 19, 19),rgb(0, 0, 0) 24.5%);
}

.calendar__title {
  text-align: center;
}

.calendar__wrapper {
  //border-radius: 64px;
  overflow: hidden;
  padding: 40px;
  //background: $white_color;
  //box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05),20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  height: 591px;
}
