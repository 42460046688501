html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: linear-gradient(314deg, #202020 0%, #292929 50.13%, #202020 78.52%, #202020 100%);
  color: #181818;
  margin: 0;
  padding: 0;
  font-family: "Gilroy", sans-serif;
  /* Don't forget about fallback font/family */
  font-size: 20px;
  line-height: normal;
  min-height: 100vh;
  position: relative;
}

#top {
  opacity: 0;
  visibility: hidden;
  height: 0;
  font-size: 0;
}

nav ul {
  list-style: none;
}

.logo {
  display: inline-block;
  height: auto;
}

.logo > img {
  object-fit: contain;
}

img {
  max-width: 100%;
  height: auto;
}

nav a {
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

a.button:focus,
a.button:focus-visible {
  outline: 1px solid #e6fdfa;
}

.nav-menu {
  margin: 0;
  padding: 0;
}

.to-top-button {
  position: fixed;
  inset-block-end: 50px;
  inset-inline-end: 50px;
  display: block;
  background-image: url(../../assets/img/ic-top-arrow.svg);
  background-position: center center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  background-color: #0EC7B3;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  transition: ease-in-out 0.3s;
}

.to-top-button--visible {
  visibility: visible;
  opacity: 1;
  transition: ease-in-out 0.3s;
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
}

@media (max-width: 576px) {
  .to-top-button {
    width: 30px;
    height: 30px;
    inset-block-end: 20px;
    inset-inline-end: 10px;
    background-size: 70%;
  }
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  src: local("Gilroy"), url("../../assets/fonts/Gilroy-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  src: local("Gilroy"), url("../../assets/fonts/Gilroy-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
::-webkit-input-placeholder {
  font-family: "Gilroy";
}

::-moz-placeholder {
  font-family: "Gilroy";
}

:-ms-input-placeholder {
  font-family: "Gilroy";
}

:-moz-placeholder {
  font-family: "Gilroy";
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

.container,
.container-fluid {
  width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding: 0 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 976px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
.class-color {
  color: lightcoral;
  font-family: monospace;
}

.class-name-color {
  color: yellowgreen;
  font-family: monospace;
  line-height: 3em;
}

.first-title {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-size: clamp(36px, 5vw, 80px);
  line-height: normal;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
}

.second-title, .trade__item-number::before {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-size: clamp(28px, 3vw, 40px);
  line-height: normal;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
}

.third-title {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-size: clamp(26px, 3vw, 32px);
  line-height: normal;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
}

.small-title {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0.2px;
  font-weight: 700;
  line-height: normal;
  color: #ffffff;
  margin: 0;
}

.main-text, .slider__item-title {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: clamp(16px, 2vw, 24px);
  line-height: normal;
  color: #ffffff;
  margin: 0;
}

.small-text {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.16px;
  line-height: normal;
  color: #ffffff;
  margin: 0;
}

.white-text {
  color: #ffffff;
}

.button {
  color: #181818;
  border: none;
  border-radius: 15px;
  min-height: 64px;
  padding: 20px 37px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #12ecd1;
  cursor: pointer;
  letter-spacing: 0.03em;
  font-family: inherit;
  font-weight: 700;
  font-size: clamp(16px, 3vw, 20px);
  line-height: normal;
  transition: ease-in-out 0.3s;
  text-decoration: none;
  box-shadow: 10px 10px 20px 0 rgba(211, 174, 14, 0.1), -10px -10px 20px 0 rgba(255, 236, 152, 0.1);
  text-transform: uppercase;
}

.button:hover {
  transform: scale(1.01);
  transition: ease-in-out 0.3s;
}

.button:active {
  transform: translate(2px, 1px) scale(0.99);
  transition: none;
}

@media (max-width: 768px) {
  .button {
    min-height: 45px;
    padding: 12px 20px;
  }
}
.is-visible {
  visibility: visible;
  opacity: 1;
  transition: ease-in-out 0.3s;
  display: grid;
}

.hamburger {
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.9;
}

.hamburger.is-active:hover {
  opacity: 0.9;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #181818;
}

.hamburger-box {
  width: 20px;
  height: 12px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 20px;
  height: 1px;
  background-color: #181818;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -5px;
}

.hamburger-inner::after {
  bottom: -5px;
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -10px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner {
  bottom: -3px;
  transition: ease-in-out 0.3s;
}

.hamburger--collapse .hamburger-inner {
  transition: ease-in-out 0.3s;
}

.hamburger__inner-text {
  display: inline-block;
  color: #181818;
  margin-inline-start: 24px;
  font-family: inherit;
  text-transform: uppercase;
  font-size: 18px;
}

@media (max-width: 480px) {
  .hamburger__inner-text {
    margin-inline-start: 10px;
  }
}
.header {
  background-color: rgba(15, 13, 14, 0.6);
  position: fixed;
  width: 100%;
  inset-block-start: 0;
  z-index: 999;
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.header--sticky {
  background-color: #0f0d0e;
  transition: ease-in-out 0.3s;
}

.header__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__nav-menu {
  position: absolute;
  margin: 0;
  inset-inline-start: 0;
  inset-block-start: 100%;
  height: calc(100vh - 100%);
  z-index: 999;
  background-color: #181818;
  color: #ffffff;
  transform: translateX(-100%);
  visibility: hidden;
  transition: ease-in-out 0.3s;
  padding-inline-start: calc((100vw - 1200px)/2);
  padding-inline-end: 200px;
  padding-block-start: 32px;
  font-size: 12px;
}

.header__nav-menu-item:not(:last-child) {
  margin-block-end: 10px;
}

.header__nav-menu-item a {
  display: inline-block;
  padding: 3px 0;
  font-size: inherit;
  transition: ease-in-out 0.3s;
}

.header__nav-menu-item:hover:not(.header__nav-menu-item--is-active) a {
  transition: ease-in-out 0.3s;
  transform: translateX(3px);
}

.nav-menu--opened {
  visibility: visible;
  transform: translateX(0);
  transition: ease-in-out 0.3s;
}

.header__nav-actions {
  display: flex;
  align-items: center;
  column-gap: 32px;
}

.lang-menu {
  margin: 0;
  padding: 0;
  padding-block-end: 7px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.lang-item {
  position: relative;
  cursor: pointer;
}

.lang-item::after {
  content: "";
  background-image: url(../../assets/img/ic-dropdown.svg);
  width: 24px;
  height: 24px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(50%);
  position: relative;
  inset-block-end: 6px;
}

.lang-menu__sub-menu {
  padding: 0;
  position: absolute;
  inset-block-start: 24px;
  inset-inline-start: 0;
  border-radius: 5px;
  transform-origin: 0 0;
  transform: scaleY(0);
  visibility: hidden;
  transition: ease-in-out 0.3s;
  overflow: hidden;
  z-index: 9999;
}

.lang-menu:hover .lang-menu__sub-menu {
  transform-origin: 0 0;
  transform: scaleY(1);
  visibility: visible;
  transition: ease-in-out 0.3s;
  padding-top: 10px;
}

.lang-menu__sub-item a {
  display: inline-block;
  font-family: inherit;
  padding: 8px;
  background: #262526;
  transition: ease-in-out 0.3s;
  width: 100%;
}

.lang-menu__sub-item:hover a {
  background: black;
  transition: ease-in-out 0.3s;
}

.call-me-button {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.call-me-button:hover {
  animation: vibrate 1s infinite;
}

.call-me-button:active {
  transform: scale(1.02);
  animation: none;
}

@keyframes vibrate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0);
  }
}
@media (min-width: 1920px) {
  .header .container {
    max-width: unset;
    padding: 0 30px;
  }

  .header__nav-menu {
    padding-inline-start: 30px;
  }
}
@media (max-width: 1199px) {
  .header__nav-menu {
    padding-inline-start: calc((100vw - 976px)/2);
  }
}
@media (max-width: 991px) {
  .header__nav-menu {
    padding-inline-start: calc((100vw - 720px)/2);
  }
}
@media (max-width: 767px) {
  .header__nav-menu {
    padding-inline-start: calc((100vw - 540px)/2);
  }
}
@media (max-width: 576px) {
  .header {
    padding: 14px 0;
  }

  .logo {
    width: 52px;
    display: inline-flex;
    align-items: center;
  }

  .call-me-button {
    display: inline-flex;
    width: 32px;
    height: 32px;
  }

  .header__nav-menu {
    padding-inline-start: 15px;
  }
}
@media (max-width: 480px) {
  .header__nav-actions {
    column-gap: 12px;
  }
}
@media (max-width: 420px) {
  .header__nav-menu {
    padding-inline-end: 150px;
  }
}
.hamburger {
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.9;
}

.hamburger.is-active:hover {
  opacity: 0.9;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #ffffff;
}

.hamburger-box {
  width: 20px;
  height: 12px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 20px;
  height: 1px;
  background-color: #ffffff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -5px;
}

.hamburger-inner::after {
  bottom: -5px;
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -10px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner {
  bottom: -3px;
  transition: ease-in-out 0.3s;
}

.hamburger--collapse .hamburger-inner {
  transition: ease-in-out 0.3s;
}

.hamburger__inner-text {
  display: inline-block;
  color: #ffffff;
  margin-inline-start: 24px;
  font-family: inherit;
  text-transform: uppercase;
  font-size: 18px;
}

@media (max-width: 480px) {
  .hamburger__inner-text {
    margin-inline-start: 10px;
    font-size: 16px;
  }
}
.hero {
  background-image: url(../../assets/img/home-hero.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  min-height: 800px;
  position: relative;
}

.hero__content {
  position: absolute;
  inset-block-start: 50%;
  transform: translateY(-50%);
  max-width: 510px;
}

.hero__desc {
  margin: 24px 0;
}

.hero__content-actions {
  display: flex;
  gap: 24px;
}

@media (max-width: 1199px) {
  .hero {
    background-size: 80%;
    background-position: right center;
  }
}
@media (max-width: 991px) {
  .hero {
    min-height: 440px;
  }
}
@media (max-width: 768px) {
  .hero {
    min-height: 500px;
    background-position: 125% 60%;
  }
}
@media (max-width: 576px) {
  section.hero {
    background-image: none;
    padding: 120px 0;
    padding-block-start: 180px;
  }

  .hero__content {
    position: static;
    transform: none;
    text-align: center;
    width: 100%;
  }

  .hero__content-actions {
    gap: 10px;
    flex-direction: column;
  }
}
.triggers {
  padding-block-start: 80px;
  padding-block-end: 80px;
  background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 14.5%, #171313 100%);
  position: relative;
}

.triggers::before {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  inset-block-start: -100px;
  height: calc(100% + 100px);
  background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 14.5%, #171313 100%);
}

.triggers__title,
.triggers__subscription {
  text-align: center;
  z-index: 1;
  position: relative;
}

.triggers__subscription {
  margin-block-start: 20px;
  margin-block-end: 40px;
}

.triggers__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
  width: 100%;
  justify-content: center;
  text-align: center;
  border-radius: 64px;
  border: 2px solid #12ecd1;
  padding: 74px 0;
  margin: 0;
  backdrop-filter: blur(7px);
}

.triggers__item-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
}

.triggers__item {
  position: relative;
}

.triggers__item:not(:last-child)::after {
  content: "";
  display: block;
  width: 1px;
  height: 140px;
  inset-block-start: 50%;
  transform: translateY(-50%);
  background-color: #0E0D11;
  position: absolute;
  inset-inline-end: 0;
}

@media (max-width: 767px) {
  .triggers__list {
    grid-template-columns: 1fr;
    padding: 44px 0;
    gap: 50px;
  }

  .triggers__item:not(:last-child)::after {
    content: none;
  }

  .triggers__item {
    padding: 0 10px;
  }

  .triggers__item-text {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .triggers__item:not(:last-child)::after {
    height: 1px;
    width: 100px;
    inset-block-start: auto;
    inset-block-end: -25px;
    inset-inline-start: 50%;
    transform: translate(-50%, 0);
    background-color: #0ec7b3;
    position: absolute;
  }
}
.slider {
  padding-block-start: 64px;
  background-color: #171313;
}

.slider__title {
  text-align: center;
  max-width: 997px;
  margin: 0 auto;
  margin-block-end: 54px;
}

.slider__subscription {
  text-align: center;
  margin-block-end: 40px;
}

.slider__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.slider__item {
  max-width: 25%;
}

.slider__item-wrap {
  padding: 32px;
  text-align: center;
}

.slider__item-ic {
  display: inline-block;
  width: 41px;
  height: 41px;
  margin-block-end: 32px;
}

.slider__item-ic > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.slider__item-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
}

.slider__item-text {
  margin-block-start: 12px;
}

.slider__content-actions {
  text-align: center;
}

@media (max-width: 991px) {
  .slider__item {
    max-width: 50%;
  }
}
@media (max-width: 480px) {
  .slider__item {
    max-width: 100%;
  }
}
.start-trading {
  padding-block-end: 64px;
}

.start-trading__title,
.start-trading__subscription {
  text-align: center;
}

.start-trading .triggers__list {
  grid-template-columns: repeat(3, 1fr);
}

.start-trading .triggers__item:not(:last-child)::after {
  content: "";
  display: block;
  width: 32px;
  height: 32px;
  background-image: url(../../assets/img/ic-arrow.svg);
  inset-block-start: 50%;
  transform: translateY(-50%);
  position: absolute;
  inset-inline-end: 0;
  background-color: transparent;
}

.start-trading .triggers__item-ic {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  color: #12ecd1;
  margin-block-end: 16px;
  display: inline-block;
}

.start-trading__desc {
  font-size: 16px;
  margin: 0 auto;
  margin-block-start: 8px;
  max-width: 224px;
}

.start-trading__content-actions {
  z-index: 1;
  position: relative;
  text-align: center;
  padding-block-start: 40px;
}

@media (max-width: 991px) {
  .start-trading .triggers__item:not(:last-child)::after {
    content: none;
  }
}
@media (max-width: 767px) {
  .start-trading .triggers__list {
    grid-template-columns: 1fr;
  }
}
.calendar {
  padding-block-start: 80px;
  padding-block-end: 64px;
  background: linear-gradient(180deg, #171313, black 24.5%);
}

.calendar__title {
  text-align: center;
}

.calendar__wrapper {
  overflow: hidden;
  padding: 40px;
  height: 591px;
}

.questions {
  padding-block-end: 80px;
  background: linear-gradient(180deg, rgba(1, 1, 1, 0), #010101 11.5%);
  position: relative;
}

.questions::before {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  inset-block-start: -120px;
  height: calc(100% + 120px);
  background: linear-gradient(180deg, rgba(1, 1, 1, 0), #010101 11.5%);
}

.questions__form {
  display: flex;
  column-gap: 24px;
  justify-content: center;
}

.questions__form fieldset {
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.questions__field {
  box-sizing: border-box;
  border: 2px solid #12ecd1;
  border-radius: 15px;
  color: #ffffff;
  font-size: 24px;
  min-height: 90px;
  padding: 28px 31px;
  background-color: transparent;
  margin-block-end: 16px;
}

.questions__field::placeholder {
  color: #ffffff;
  font-size: 24px;
}

.questions__field--textarea {
  resize: none;
  min-height: 193px;
}

.questions__form-submit-btn {
  min-height: 85px;
}

@media (max-width: 991px) {
  .questions__form {
    flex-wrap: wrap;
  }

  .questions__form fieldset,
.questions__form fieldset label,
.questions__form fieldset input,
.questions__form fieldset textarea {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .questions__field {
    min-height: 50px;
    padding: 18px 25px;
  }

  .questions__field--textarea {
    min-height: 120px;
  }

  .questions__field::placeholder {
    color: #ffffff;
    font-size: 16px;
  }

  .questions__form-submit-btn {
    min-height: 50px;
  }
}
.trade {
  padding-block-end: 64px;
}

.trade__title {
  text-align: center;
}

.trade__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  padding-block-start: 56px;
}

.trade__img {
  width: 590px;
  height: 544px;
  flex-grow: 1;
}

.trade__img > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.trade__list {
  flex-grow: 1;
  list-style: none;
  counter-reset: trade-counter;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  padding: 0;
}

.trade__item:not(:last-of-type) {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 592px;
  background-color: rgba(255, 255, 255, 0.28);
  border-radius: 64px;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05), 20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  padding: 32px 16px;
  text-align: center;
}

.trade__item--button {
  margin-block-start: 8px;
}

.trade__item--button .button {
  width: 100%;
}

.trade__item-number {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 48px;
}

.trade__item-number::before {
  content: counter(trade-counter);
  counter-increment: trade-counter;
  position: absolute;
  color: #FE6238;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
}

.trade__item-title {
  margin-block-start: 12px;
  margin-block-end: 8px;
}

@media (max-width: 768px) {
  .trade__img {
    display: none;
  }
}
.get-start {
  padding-block-end: 170px;
}

.get-start__title {
  text-align: center;
}

.get-start__list {
  padding: 56px 142px;
  margin: 0;
  margin-block-start: 56px;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.28);
  border-radius: 64px;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05), 20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.get-start__button {
  margin: 0 auto;
  margin-block-start: 32px;
  max-width: 390px;
  display: flex;
}

@media (max-width: 991px) {
  .get-start__list {
    padding: 32px 54px;
    gap: 12px;
  }
}
.about-hero {
  background-image: url(../../assets/img/about-hero.png);
}

.times {
  padding-block-start: 65px;
  padding-block-end: 64px;
}

.times__title {
  text-align: center;
  margin-block-end: 54px;
}

.about-color {
  background: #161212;
  background: linear-gradient(360deg, #161212 0%, #171313 100%);
}

.trading-hero {
  background-image: url(../../assets/img/trading-hero.png);
}

.tariffs {
  padding-block-start: 64px;
  background: #171313;
  background: linear-gradient(360deg, #171313 0%, #1f1f1f 100%);
  padding-bottom: 4em;
}

.tariffs__title {
  text-align: center;
}

.tariffs__plans {
  margin: 0;
  padding: 0;
  list-style: none;
}

.plans {
  padding-block-start: 54px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 16px;
  width: 100%;
}

.plans__item {
  border-radius: 64px;
  backdrop-filter: blur(7px);
  padding: 48px 39px;
  padding-block-end: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #12ecd1;
}

.plans__item-title {
  text-align: center;
  color: #12ecd1;
}

.plans__services {
  margin: 0;
  padding: 0;
  padding-block-start: 24px;
  padding-block-end: 36px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
}

.plans__item-price {
  text-align: center;
  margin-block-end: 16px;
}

.plans__service--grey {
  color: #8B8B8B;
}

@media (max-width: 991px) {
  .plans {
    gap: 32px;
    flex-wrap: wrap;
  }
}
.study-hero {
  background-image: url(../../assets/img/study-hero.png);
}

.faq {
  padding: 64px 0;
}

.faq__title {
  text-align: center;
}

.faq__tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-block-start: 54px;
  padding-block-end: 36px;
}

.tab-button {
  min-width: 288px;
  background: transparent;
  border: 1px solid #12ecd1;
  color: #12ecd1;
  box-shadow: none;
  text-transform: none;
  line-height: 20px;
}

.tab-button--is-active {
  background-color: #12ecd1;
  color: #181818;
  transition: ease-in-out 0.3s;
  box-shadow: 10px 10px 20px 0 rgba(43, 135, 126, 0.15), -10px -10px 20px 0 rgba(100, 252, 236, 0.15);
}

.accordion {
  width: 100%;
  display: none;
}

.accordion--is-active {
  display: block;
}

.accordion__container {
  transition: ease-in-out 0.3s;
  background-color: transparent;
  backdrop-filter: blur(7px);
  border-radius: 15px;
  overflow: hidden;
  margin-block-end: 16px;
  border: 1px solid #12ecd1;
}

.accordion__question {
  appearance: none;
  list-style: none;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  padding: 0 54px;
  padding-inline-end: 100px;
  color: #ffffff;
  min-height: 86px;
  display: flex;
  align-items: center;
  position: relative;
}

.accordion__question::-webkit-details-marker {
  -webkit-appearance: none;
  display: none;
}

.accordion__question::after {
  content: "";
  background-image: url(../../assets/img/ic-acc-drop.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  display: block;
  transition: ease-in-out 0.3s;
  position: absolute;
  inset-inline-end: 54px;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.accordion__container[open] .accordion__question::after {
  transform: translateY(-50%) rotate(90deg);
  transition: ease-in-out 0.3s;
}

.accordion__container[open] .accordion__question {
  color: #ffffff;
  transition: ease-in-out 0.3s;
}

.accordion__answer {
  padding: 24px 54px 48px;
  color: #8B8B8B;
  font-size: 18px;
}

.faq-bg {
  background: #202020;
  background: linear-gradient(360deg, #202020 0%, #171313 100%);
}

@media (max-width: 991px) {
  .faq__tabs {
    justify-content: center;
  }
}
.news-hero {
  background-image: url(../../assets/img/news-hero.png);
}

.news {
  padding: 64px 0;
}

.news__title {
  text-align: center;
}

.news__list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-block-start: 54px;
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.news__item {
  background-color: transparent;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  border: 1px solid #12ecd1;
}

.news__item-img {
  min-width: 200px;
  max-width: 200px;
}

.news__item-img > img {
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.news__content {
  position: relative;
  padding: 24px;
  padding-block-start: 80px;
  padding-block-end: 36px;
}

.news__data {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-left: none;
  color: #12ecd1;
  padding: 20px 24px;
}

.news__tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #12ecd1;
  color: #181818;
  padding: 5px 14px;
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  top: 22px;
  right: 24px;
  text-transform: uppercase;
}

.news__desc {
  margin-block-end: 24px;
  font-size: 20px;
  color: #ffffff;
}

.news .button {
  line-height: 16px;
  padding: 12px 24px;
  min-height: 48px;
}

.pagination {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-block-start: 32px;
}

.pagination__button {
  border-radius: 50%;
  color: #181818;
  width: 40px;
  height: 40px;
  min-height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  padding: 0;
  font-weight: 300;
  padding-block-start: 3px;
  background: transparent;
  border: 1px solid #12ecd1;
  border-radius: 5px;
  color: #12ecd1;
  box-shadow: none;
}

.pagination__button:hover {
  transform: none;
}

.pagination__button--is-active {
  background-color: #12ecd1;
  color: #181818;
}

.news__tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.news__tabs .tab-button {
  min-width: unset;
  text-transform: uppercase;
  font-size: 12px;
  padding: 8px 18px;
  min-height: unset;
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .news__list {
    grid-template-columns: minmax(0, 592px);
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .news__item-img {
    min-width: 120px;
    max-width: 120px;
  }
}
@media (max-width: 480px) {
  .news__tag {
    padding: 10px 38px;
    padding-inline-start: 30px;
  }

  .news__data {
    padding: 10px 18px;
  }
}
.news {
  background: #120f0f;
  background: linear-gradient(360deg, #120f0f 0%, #202020 100%);
}

.calendar {
  top: -10px;
  position: relative;
}

.calendar.calendar-bg {
  padding-top: 0;
  padding-bottom: 0;
  top: 0;
}

.triggers.start-trading {
  top: -10px;
}

.footer {
  box-shadow: 0px 20px 40px 0px rgba(32, 43, 65, 0.05), -15px 4px 30px 0px rgba(32, 43, 65, 0.05);
  background: black;
  padding: 32px 0;
  padding-block-end: 0;
}

.footer__nav,
.footer__contacts-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__menu-item {
  display: inline-block;
  margin-inline-end: 30px;
}

.footer__menu-link,
.footer__contacts-link {
  font-size: 16px;
}

.footer__menu,
.footer__contacts-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__contacts-nav {
  padding: 40px 0;
}

.footer__button {
  border-radius: 40px;
  font-size: 16px;
  padding: 6px 32px;
  max-height: 32px;
  min-height: unset;
}

.footer__info-wrap {
  margin-block-end: 20px;
  max-width: 88%;
}

.footer__subfooter {
  background: #282828;
  padding: 10px 0;
}

.footer__subfooter .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .footer__nav,
.footer__menu-item {
    display: block;
  }

  .footer__menu {
    padding: 20px 0;
  }

  .footer__menu-item:not(:last-child) {
    margin-block-end: 14px;
  }
}
@media (max-width: 576px) {
  .footer__logo {
    width: 140px;
  }
}
@media (max-width: 480px) {
  .footer__contacts-menu {
    flex-direction: column;
    row-gap: 14px;
  }

  .footer__nav {
    text-align: center;
  }

  .footer__menu-item {
    margin-inline-end: 0;
  }
}
.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}