.header {
  background-color: rgba(15, 13, 14, 0.60);
  position: fixed;
  width: 100%;
  inset-block-start: 0;
  z-index: 999;
  transition: $transition_base;
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.header--sticky {
  background-color: rgba(15, 13, 14, 1);;
  transition: $transition_base;
}

.header__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__nav-menu {
  position: absolute;
  margin: 0;
  inset-inline-start: 0;
  inset-block-start: 100%;
  height: calc(100vh - 100%);
  z-index: 999;
  background-color: $black_color;
  color: $white_color;
  transform: translateX(-100%);
  visibility: hidden;
  transition: $transition_base;
  padding-inline-start: calc((100vw - 1200px)/2);
  padding-inline-end: 200px;
  padding-block-start: 32px;
  font-size: 12px;
}

.header__nav-menu-item:not(:last-child) {
  margin-block-end: 10px;
}

.header__nav-menu-item a {
  display: inline-block;
  padding: 3px 0;
  font-size: inherit;
  transition: $transition_base;
}

.header__nav-menu-item--is-active a {
  //color: $yellow_color;
}

.header__nav-menu-item:hover:not(.header__nav-menu-item--is-active) a {
  //color: lighten($yellow_color, 35%);
  transition: $transition_base;
  transform: translateX(3px);
}

.nav-menu--opened {
  visibility: visible;
  transform: translateX(0);
  transition: $transition_base;
}

.header__nav-actions {
  display: flex;
  align-items: center;
  column-gap: 32px;
}

.lang-menu {
  margin: 0;
  padding: 0;
  padding-block-end: 7px;
  color: $white_color;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.lang-item {
  position: relative;
  cursor: pointer;
}

.lang-item::after {
  content: '';
  background-image: url(../../assets/img/ic-dropdown.svg);
  width: 24px;
  height: 24px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(50%);
  position: relative;
  inset-block-end: 6px;
}

.lang-menu__sub-menu {
  padding: 0;
  position: absolute;
  inset-block-start: 24px;
  inset-inline-start: 0;
  border-radius: 5px;
  transform-origin: 0 0;
  transform: scaleY(0);
  visibility: hidden;
  transition: $transition_base;
  overflow: hidden;
  z-index: 9999;
}

.lang-menu:hover .lang-menu__sub-menu {
  transform-origin: 0 0;
  transform: scaleY(1);
  visibility: visible;
  transition: $transition_base;
  padding-top: 10px;
}

.lang-menu__sub-item a {
  display: inline-block;
  font-family: inherit;
  padding: 8px;
  background: rgb(38, 37, 38);
  transition: $transition_base;
  width: 100%;
}

.lang-menu__sub-item:hover a {
  background: darken(rgb(38, 37, 38), 20%);
  transition: $transition_base;
}

.call-me-button {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.call-me-button:hover {
  animation: vibrate 1s infinite;
}

.call-me-button:active {
  transform: scale(1.02);
  animation: none;
}

@keyframes vibrate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-10deg)
  }

  100% {
    transform: rotate(0);
  }
}

// Media
@media (min-width: 1920px) {
  .header .container {
    max-width: unset;
    padding: 0 30px;
  }

  .header__nav-menu {
    padding-inline-start: 30px;
  }
}

@media (max-width: 1199px) {
  .header__nav-menu {
    padding-inline-start: calc((100vw - 976px)/2);
  }
}

@media (max-width: 991px) {
  .header__nav-menu {
    padding-inline-start: calc((100vw - 720px)/2);
  }
}


@media (max-width: 767px) {
  .header__nav-menu {
    padding-inline-start: calc((100vw - 540px)/2);
  }
}

@media (max-width: 576px) {
  .header {
    padding: 14px 0;
  }

  .logo {
    width: 52px;
    display: inline-flex;
    align-items: center;
  }

  .call-me-button {
    display: inline-flex;
    width: 32px;
    height: 32px;
  }

  .header__nav-menu {
    padding-inline-start: 15px;
  }
}

@media (max-width: 480px) {
  .header__nav-actions {
    column-gap: 12px;
  }
}

@media (max-width: 420px) {
  .header__nav-menu {
    padding-inline-end: 150px;
  }
}


.hamburger {
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.9;
}

.hamburger.is-active:hover {
  opacity: 0.9;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: $white_color;
}

.hamburger-box {
  width: 20px;
  height: 12px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 20px;
  height: 1px;
  background-color: $white_color;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -5px;
}

.hamburger-inner::after {
  bottom: -5px;
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -10px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
  transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
  transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}


.hamburger--collapse.is-active .hamburger-inner {
  bottom: -3px;
  transition: $transition_base;
}

.hamburger--collapse .hamburger-inner {
  transition: $transition_base;
}

.hamburger__inner-text {
  display: inline-block;
  color: $white_color;
  margin-inline-start: 24px;
  font-family: inherit;
  text-transform: uppercase;
  font-size: 18px;
}

@media (max-width: 480px) {
  .hamburger__inner-text {
    margin-inline-start: 10px;
    font-size: 16px;
  }
}
