.study-hero {
  background-image: url(../../assets/img/study-hero.png);
}

.faq {
  padding: 64px 0;
}

.faq__title {
  text-align: center;
}

.faq__tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-block-start: 54px;
  padding-block-end: 36px;
}

.tab-button {
  min-width: 288px;
  background: transparent;
  border: 1px solid $main_color;
  color: $main_color;
  box-shadow: none;
  text-transform: none;
  line-height: 20px;
}

.tab-button--is-active {
  background-color: $main_color;
  color: $black_color;
  transition: $transition_base;
  box-shadow: 10px 10px 20px 0 rgba(43, 135, 126, 0.15), -10px -10px 20px 0 rgba(100, 252, 236, 0.15);
}

.accordion {
  width: 100%;
  display: none;
}

.accordion--is-active {
  display: block;
}

.accordion__container {
  transition: $transition-base;
  background-color: transparent;
  // box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05),20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  border-radius: 15px;
  overflow: hidden;
  margin-block-end: 16px;
  border: 1px solid $main_color;
}

.accordion__question {
  appearance: none;
  list-style: none;
  cursor: pointer;
  transition: $transition-base;
  padding: 0 54px;
  padding-inline-end: 100px;
  color: $white_color;
  min-height: 86px;
  display: flex;
  align-items: center;
  position: relative;
}

.accordion__question::-webkit-details-marker {
  -webkit-appearance: none;
  display: none;
}

.accordion__question::after {
  content: '';
  background-image: url(../../assets/img/ic-acc-drop.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  display: block;
  transition: $transition-base;
  position: absolute;
  inset-inline-end: 54px;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.accordion__container[open] .accordion__question::after {
  transform: translateY(-50%) rotate(90deg);
  transition: $transition-base;
}

.accordion__container[open] .accordion__question {
  color: $white_color;
  transition: $transition-base;
}

.accordion__answer {
  padding: 24px 54px 48px;
  color: $grey_color;
  font-size: 18px;
}
.faq-bg {
  background: rgb(32,32,32);
background: linear-gradient(360deg, rgba(32,32,32,1) 0%, rgba(23,19,19,1) 100%);
}

@media (max-width: 991px) {
  .faq__tabs {
    justify-content: center;
  }
}
