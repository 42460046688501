.footer {
  box-shadow: 0px 20px 40px 0px rgba(32, 43, 65, 0.05),-15px 4px 30px 0px rgba(32, 43, 65, 0.05);
  background: rgba(0, 0, 0, 1);
  padding: 32px 0;
  padding-block-end: 0;
}

.footer__nav,
.footer__contacts-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__menu-item {
  display: inline-block;
  margin-inline-end: 30px;
}

.footer__menu-link,
.footer__contacts-link {
  font-size: 16px;
}

.footer__menu,
.footer__contacts-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__contacts-nav {
  padding: 40px 0;
}

.footer__button {
  border-radius: 40px;
  font-size: 16px;
  padding: 6px 32px;
  max-height: 32px;
  min-height: unset;
}

.footer__info-wrap {
  margin-block-end: 20px;
  max-width: 88%;
}

.footer__subfooter {
  background: rgb(40, 40, 40);
  padding: 10px 0;
}

.footer__subfooter .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .footer__nav,
  .footer__menu-item {
    display: block;
  }

  .footer__menu {
    padding: 20px 0;
  }

  .footer__menu-item:not(:last-child) {
    margin-block-end: 14px;
  }
}

@media (max-width: 576px) {
  .footer__logo {
    width: 140px;
  }
}

@media (max-width: 480px) {
  .footer__contacts-menu {
    flex-direction: column;
    row-gap: 14px;
  }

  .footer__nav {
    text-align: center;
  }

  .footer__menu-item {
    margin-inline-end: 0;
  }
}
